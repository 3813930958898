import React, { useState, useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./success.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import bankList from "../data/banks"

const Success = () => {
  const [approvedBank, setApprovedBank] = useState(false)
  const [bankUrl, setBankUrl] = useState()

  useEffect(() => {
    if (window.history.state.formValues.bankName !== "approvedBankSelected") {
      setApprovedBank(true)
    } else {
      setApprovedBank(false)
    }

    getBanks()
  })

  function getBanks() {
    bankList.banks
      .filter(bank => bank.Bank === window.history.state.formValues.bank)
      .map((bank, index) => {
        setBankUrl(bank.link)
      })
  }

  return (
    <Layout>
      <SEO title="Success Page" />
      <div className="container primary-bg success-page">
        <section>
          <div className="card">
            <span className="spanStyle">
              <FontAwesomeIcon color="#00C720" size="6x" icon={faCheckCircle} />
              <div>
                <h1>Thank you for registering with BizHelp.Miami</h1>
              </div>
            </span>
          </div>
        </section>
        {!approvedBank && (
          <div>
            <label className="approvedTextIntro">
              You may qualify for a federally-backed, forgivable loan under the
              Paycheck Protection Program. Your existing bank is an approved SBA
              7(a) lender, and we recommend you start the PPP application
              process directly with them. The link below delivers you to their
              CARES/PPP Application portal, where they will outline next steps.
            </label>
            <div className="continueButton">
              <a
                className="button btn primary-success"
                target="_blank"
                href={bankUrl}
              >
                Continue to my bank's CARES Act/PPP Application Portal
              </a>
            </div>
            <label className="approvedTextIntro">
              To expedite the application process, we recommend that you have
              the following standard documentation on hand:
            </label>
            <ul className="bulletPointText">
              <li>
                Completed and signed PPP Borrower Application, which can be
                accessed&nbsp;
                <a
                  href="https://www.sba.gov/sites/default/files/2020-04/PPP%20Borrower%20Application%20Form.pdf"
                  target="_blank"
                >
                  here.
                </a>
              </li>
              <li>2019 IRS Quarterly 940, 941, or 944 Payroll Tax Reports </li>
              <li>
                Payroll Reports for the last 12 months, reflecting the following
                information:
                <ul className="subBullet">
                  <li>- Paid time off for each employee</li>
                  <li>- Vacation pay for each employee</li>
                  <li>- Family medical leave for each employee</li>
                  <li>
                    - State and Local taxes accessed on an employee's
                    compensation
                  </li>
                </ul>
              </li>
              <li>
                Documentation showing what the employer paid over the past 12
                months for:
                <ul className="subBullet">
                  <li>- Employee group health insurance premiums</li>
                  <li>- Sum of retirement plan funding</li>
                </ul>
              </li>
              <li>
                Funding account information, including account number and ABA
                wire transfer routing number (if potential funding will be to an
                external account)
              </li>
              <li>
                Documentation of ownership and
                Officers/Members/Managers/Trustees
              </li>
              <li>
                Contact information, including an email address and copy a valid
                dervier's license, for each owner greater than 20% ownership in
                the business
              </li>
            </ul>
            <label className="approvedTextIntro">
              While certain requirements may vary by bank, the above checklist
              includes standard information that will be required for loan
              applications
            </label>
            <figure>
              <div className="goToButton">
                <a
                  className="button btn non-primary-success"
                  target="_blank"
                  href="https://home.treasury.gov/system/files/136/Paycheck-Protection-Program-Application-3-30-2020-v3.pdf"
                >
                  Download official PPP loan application for Small Businesses
                </a>
              </div>
            </figure>
            <Link className="full-cta" to="/additional-funding">
                Additional Funding Sources
              </Link>
          </div>
        )}
        {approvedBank && (
          <div className="container">
            <div className="unapprovedDiv">
              <span className="unapprovedStyle">
                You may qualify for a federally-backed, forgivable loan under
                the Paycheck Protection Program. Your bank is not an
                SBA-approved lender at this time, but this does not impact your
                eligibility.
              </span>
            </div>
            <div className="unapprovedDiv">
              <span className="unapprovedStyle">
                You can still apply for existing programs via other
                participating institutions.
              </span>
            </div>
            <div className="link">
              <hr></hr>
              <Link className="full-cta" to="/new-client-banks">
                Connect with an SBA-approved lender that is taking new clients
                and continue the application process.
              </Link>
              <Link className="full-cta" to="/additional-funding">
                Additional Funding Sources
              </Link>
            </div>
          </div>
        )}
      </div>
    </Layout>
  )
}

export default Success
