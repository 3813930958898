export const Banks = {
  banks: [
    {
      "Bank": "121 Financial CU",
      "link": "https://blog.121fcu.org/covid-19-info-business-members?_ga=2.177759269.1225774872.1586203729-38953584.1586203729",
      "accepting": "No",
      "Logo": "https://www.google.com/search?q=121+Financial+CU+logo&tbm=isch&source=iu&ictx=1&fir=fZrvIA1e7I5yaM%253A%252CTj5-nQjeZtRfqM%252C_&vet=1&usg=AI4_-kTW3EBsJ-zPqKG-cxqWJ3k-Ts8sXw&sa=X&ved=2ahUKEwiI2tnC09ToAhVPV6wKHV2lAOgQ9QEwA3oECAoQGg#imgrc=fZrvIA1e7I5yaM",
    },
    {
      "Bank": "Achieva CU",
      "link": "https://www.achievacu.com/PDFs/PPP-Checklist-and-APP.pdf",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=achieva+cu&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwi3477e69ToAhX4lXIEHUNUBM0Q_AUoBHoECBEQBg&biw=1104&bih=620#imgrc=xyWgQzrEiP_UXM",
    },
    {
      "Bank": "Addition Financial CU",
      "link": "https://www.additionfi.com/business/loans-credit-cards/sba-paycheck-protection-program-loan",
      "accepting": "Yes",
      "Logo Link": "https://www.additionfi.com/business/loans-credit-cards/sba-paycheck-protection-program-loan",
    },
    {
      "Bank": "Amerant Bank",
      "link": "https://www.amerantbank.com/cares-act-sba-paycheck-protection-program/?_ga=2.212051924.1943157538.1586353647-1189429880.1586353647",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=Amerant+Bank,+National+Association&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwickZSC7NToAhUcgnIEHQhDAMoQ_AUoA3oECA0QBQ&biw=1104&bih=620#imgrc=YmgUa2VjeVJrMM",
    },
    {
      "Bank": "Amerasia Bank",
      "link": "https://www.amerasiabankny.com/business-loans.php",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=Amerant+Bank,+National+Association&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwickZSC7NToAhUcgnIEHQhDAMoQ_AUoA3oECA0QBQ&biw=1104&bih=620#imgrc=YmgUa2VjeVJrMM",
    },
    {
      "Bank": "American Momentum Bank",
      "link": "https://www.americanmomentum.bank/client-services/communication/paycheck-protection-program",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=Amerant+Bank,+National+Association&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwickZSC7NToAhUcgnIEHQhDAMoQ_AUoA3oECA0QBQ&biw=1104&bih=620#imgrc=YmgUa2VjeVJrMM",
    },
    {
      "Bank": "American National Bank",
      "link": "https://american.bank/Paycheck_Protection_Program_Loans",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Apollo Bank",
      "link": "https://www.apollobank.com/paycheck-protection-program-ppp/",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "BancorpSouth Bank",
      "link": "https://www.bancorpsouth.com/paycheck_protection_program",
      "accepting": "No",
      "Logo Link": "https://bloximages.newyork1.vip.townnews.com/leader-call.com/content/tncms/assets/v3/editorial/d/4b/d4b7e30e-5fc3-11e9-aedc-773df7d3e141/5cb4f4e36da52.image.png?resize=400%2C227",
    },
    {
      "Bank": "Banesco USA",
      "link": "https://www.banescousa.com/covid-19-programs-cares-act-ppp/",
      "accepting": "Yes",
      "Logo Link": "https://www.banescousa.com/covid-19-programs-cares-act-ppp/",
    },
    {
      "Bank": "Bank Leumi Usa",
      "link": "https://www.leumiusa.com/wp-content/uploads/2020/04/SBA-PPP-Final-Rules-_-Client-Facing-04.03.2020-130pm.pdf",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "Bank of America",
      "link": "https://about.bankofamerica.com/promo/assistance/latest-updates-from-bank-of-america-coronavirus/small-business-assistance",
      "accepting": "No",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/056/1518102340_large.png",
    },
    {
      "Bank": "Bank OZK",
      "link": "https://pages.ozk.com/paycheck-protection-program-interest.html",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=Bank+OZK+logo&rlz=1C1GCEU_enUS893US893&tbm=isch&source=iu&ictx=1&fir=1RMW2LeuPvpLnM%253A%252CgBUF5JimWRVFQM%252C_&vet=1&usg=AI4_-kRHp2FweC4J4I7FDSUbh2neDs1CYw&sa=X&ved=2ahUKEwiwsL-P2dToAhWKZd8KHcvoA-QQ9QEwAXoECAEQFQ#imgrc=1VBegwwNASuH9M",
    },
    {
      "Bank": "BankUnited",
      "link": "https://www.bankunited.com/paycheck-protection-program",
      "accepting": "No",
      "Logo Link": "https://pentagram-production.imgix.net/ded5879b-026f-4815-90da-2a51123845e3/ps_lh_bankunited_02.jpg?rect=%2C%2C%2C&w=640&fm=jpg&q=70&auto=format",
    },
    {
      "Bank": "BBVA USA ",
      "link": "https://www.bbvausa.com/special/covid19-small-business.html",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=Beach+community+bank+logo&tbm=isch&source=iu&ictx=1&fir=jUBlGgN8LSXHAM%253A%252CDUL8UoOyL29czM%252C_&vet=1&usg=AI4_-kQ5l5GGqMYvuFKKUHgdJyAE9FaX2A&sa=X&ved=2ahUKEwipn--qx9ToAhUFZN8KHdP6CAsQ9QEwAHoECAoQHA#imgrc=XUix2Ed7CwOh6M",
    },
    {
      "Bank": "Beach Community Bank",
      "link": "https://www.beachcommunitybank.com/sba/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=Beach+community+bank+logo&tbm=isch&source=iu&ictx=1&fir=jUBlGgN8LSXHAM%253A%252CDUL8UoOyL29czM%252C_&vet=1&usg=AI4_-kQ5l5GGqMYvuFKKUHgdJyAE9FaX2A&sa=X&ved=2ahUKEwipn--qx9ToAhUFZN8KHdP6CAsQ9QEwAHoECAoQHA#imgrc=XUix2Ed7CwOh6M",
    },
    {
      "Bank": "Benworth Capital",
      "link": "https://sbabizhelp.com/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=Beach+community+bank+logo&tbm=isch&source=iu&ictx=1&fir=jUBlGgN8LSXHAM%253A%252CDUL8UoOyL29czM%252C_&vet=1&usg=AI4_-kQ5l5GGqMYvuFKKUHgdJyAE9FaX2A&sa=X&ved=2ahUKEwipn--qx9ToAhUFZN8KHdP6CAsQ9QEwAHoECAoQHA#imgrc=XUix2Ed7CwOh6M",
    },
    {
      "Bank": "Black Business Investment Fund Inc. FL",
      "link": "https://bbifflorida.com/sba-relief/",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "BMO Harris Bank",
      "link": "https://www.bmoharris.com/main/personal/bmo-branches-coronavirus-update/",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Bremer Bank",
      "link": "https://www.bremer.com/",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Busey Bank ",
      "link": "https://www.busey.com/financialreliefprogram",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "Cadence Bank",
      "link": "https://cadencebank.com/alerts/coronavirus-paycheck-protection-program",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Capital City Bank",
      "link": "https://ccbgsba.com/",
      "accepting": "No",
      "Logo Link": "https://dynl.mktgcdn.com/p/fm5ZK_N6G_xK5KSj1jzAoRmHUz6bPV8k2H0eZeizfn4/3000x3000.png",
    },
    {
      "Bank": "Centennial Bank FL ",
      "link": "https://www.my100bank.com/business-banking/loans/sba-loans/",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "CenterState Bank",
      "link": "https://www.centerstatebank.com/paycheck-protection-program/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=CenterState+Bank,+National+Association+logo&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiI59TV3dToAhWqUt8KHRi0BvwQ_AUoAXoECAwQAw&biw=1104&bih=620#imgrc=5-fHXpIW3ANvrM",
    },
    {
      "Bank": "Central Bank",
      "link": "https://www.centralbank.net/small-business/loans/sba-paycheck-protection-program/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=central+bank+logo&rlz=1C1GCEU_enUS893US893&tbm=isch&source=iu&ictx=1&fir=SlPsO0hIFE37bM%253A%252CT5NAET2obcBe6M%252C_&vet=1&usg=AI4_-kRLpNzqeDlTXj4wnOD8qWoGa1vd6w&sa=X&ved=2ahUKEwjss_2y5tToAhVZmXIEHchPDlcQ9QEwB3oECAEQOw#imgrc=r_3PW2ENlnGW2M",
    },
    {
      "Bank": "CIBC Bank USA ",
      "link": "https://us.cibc.com/en/about-us/cibc-announces-measures-to-support-us-clients-impacted-by-covid-19.html",
      "accepting": "No",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "Citibank, N.A",
      "link": "https://online.citi.com/US/JRS/pands/detail.do?ID=paycheck-protection-program",
      "accepting": "Yes",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "Citizens Bank of Florida ",
      "link": "https://www.citizensbank.com/small-business/paycheck-protection-program.aspx",
      "accepting": "Yes",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "Citizens Bank ",
      "link": "https://www.citizensbank.com/small-business/paycheck-protection-program.aspx",
      "accepting": "Yes",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "Citizens First Bank ",
      "link": "https://www.citizensfb.com/commercial-lending.htm",
      "accepting": "Yes",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "Citizens State Bank (merged with VyStar Credit Union)",
      "link": "https://vystarcu.org/About-Us/Newsroom/Coronavirus/Small-Business-Relief-Program",
      "accepting": "No",
      "Logo Link": "https://vystarcu.org/App_Themes/VyStar/img/header-logo2.png",
    },
    {
      "Bank": "City National Bank of Florida",
      "link": "https://www.citynationalcm.com/home/about/press/coronavirusupdates/paycheckprotection",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=citi+national+bank+logo&source=lnms&tbm=isch&sa=X&ved=2ahUKEwihhqnWvdnoAhXtYd8KHfnwAiMQ_AUoAXoECA0QAw&biw=1920&bih=937#imgrc=AXzV-VLM2ntdMM",
    },
    {
      "Bank": "Cogent Bank ",
      "link": "https://www.cogentbank.net/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/imgres?imgurl=https%3A%2F%2Fwww.pngkit.com%2Fpng%2Fdetail%2F223-2238151_columbia-bank-columbia-banking-system-inc-logo.png&imgrefurl=https%3A%2F%2Fwww.pngkit.com%2Fview%2Fu2w7e6o0u2i1o0t4_columbia-bank-columbia-banking-system-inc-logo%2F&tbnid=7SQOBQAt-gcYCM&vet=12ahUKEwjb5qbVx9ToAhVMSKwKHT5bBAsQMygAegUIARDrAQ..i&docid=nfsTnEMhfhOyVM&w=820&h=478&q=columbia%20bank%20logo&ved=2ahUKEwjb5qbVx9ToAhVMSKwKHT5bBAsQMygAegUIARDrAQ",
    },
    {
      "Bank": "Columbia Bank",
      "link": "https://www.columbiabank.com/CARES",
      "accepting": "No",
      "Logo Link": "https://www.google.com/imgres?imgurl=https%3A%2F%2Fwww.pngkit.com%2Fpng%2Fdetail%2F223-2238151_columbia-bank-columbia-banking-system-inc-logo.png&imgrefurl=https%3A%2F%2Fwww.pngkit.com%2Fview%2Fu2w7e6o0u2i1o0t4_columbia-bank-columbia-banking-system-inc-logo%2F&tbnid=7SQOBQAt-gcYCM&vet=12ahUKEwjb5qbVx9ToAhVMSKwKHT5bBAsQMygAegUIARDrAQ..i&docid=nfsTnEMhfhOyVM&w=820&h=478&q=columbia%20bank%20logo&ved=2ahUKEwjb5qbVx9ToAhVMSKwKHT5bBAsQMygAegUIARDrAQ",
    },
    {
      "Bank": "Comerica Bank",
      "link": "https://www.comerica.com/campaigns/covid-19.html#2",
      "accepting": "No",
      "Logo Link": "https://mma.prnewswire.com/media/73848/comerica_bank_logo.jpg?p=publish",
    },
    {
      "Bank": "Cross River Bank",
      "link": "https://www.crossriversba.com/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=fairwinds+cu+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjNp-6qzdToAhUoU98KHRevDxIQ_AUoAXoECA4QAw&biw=1500&bih=809&dpr=2#imgrc=DSbaPe8Fk12qQM",
    },
    {
      "Bank": "East West Bank",
      "link": "https://www.eastwestbank.com/en/small-mid-size-businesses/sba-paycheck-protection-program",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=fairwinds+cu+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjNp-6qzdToAhUoU98KHRevDxIQ_AUoAXoECA4QAw&biw=1500&bih=809&dpr=2#imgrc=DSbaPe8Fk12qQM",
    },
    {
      "Bank": "Equity Bank ",
      "link": "https://www.equitybank.com/sba",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=fairwinds+cu+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjNp-6qzdToAhUoU98KHRevDxIQ_AUoAXoECA4QAw&biw=1500&bih=809&dpr=2#imgrc=DSbaPe8Fk12qQM",
    },
    {
      "Bank": "Fairwinds CU",
      "link": "https://www.fairwinds.org/business/small-business-relief.html",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=fairwinds+cu+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjNp-6qzdToAhUoU98KHRevDxIQ_AUoAXoECA4QAw&biw=1500&bih=809&dpr=2#imgrc=DSbaPe8Fk12qQM",
    },
    {
      "Bank": "Fifth Third Bank",
      "link": "https://www.53.com/content/fifth-third/en/alerts/covid-sba-cares-act.html",
      "accepting": "No",
      "Logo Link": "https://media.graytvinc.com/images/810*455/fifth+third.png",
    },
    {
      "Bank": "First American Bank",
      "link": "https://www.firstambank.com/coronavirus-business-loan-programs",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=FIRST1BANK+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwijwZ2r1NToAhXDmq0KHTbCC9oQ_AUoAXoECAwQAw&biw=1500&bih=809#imgrc=UbZ3XlBvZ-oQbM",
    },
    {
      "Bank": "First Bank",
      "link": "https://www.first1bank.com/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=FIRST1BANK+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwijwZ2r1NToAhXDmq0KHTbCC9oQ_AUoAXoECAwQAw&biw=1500&bih=809#imgrc=UbZ3XlBvZ-oQbM",
    },
    {
      "Bank": "First Bank of the Lake",
      "link": "https://firstbanklake.com/sba-loans.html",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=first+citrus+bank&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwicl5nr69ToAhUGlXIEHau-BOsQ_AUoBHoECAsQBg&biw=1104&bih=620#imgrc=VfXI92ZyrdFXoM",
    },
    {
      "Bank": "First Citrus Bank",
      "link": "http://www.firstcitrus.com/coronavirus-update/business-relief-programs",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=first+citrus+bank&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwicl5nr69ToAhUGlXIEHau-BOsQ_AUoBHoECAsQBg&biw=1104&bih=620#imgrc=VfXI92ZyrdFXoM",
    },
    {
      "Bank": "First Commerce CU",
      "link": "https://www.firstcommercecu.org/business/borrow/sba-loans/cares-act/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=First+Commerce+CU+LOGO&tbm=isch&source=iu&ictx=1&fir=Ws8pqP4pNgu6RM%253A%252CiEjERzyTxaK0PM%252C_&vet=1&usg=AI4_-kQ3Ti7ZbghXmJpPmS_w8YaYIyYyYQ&sa=X&ved=2ahUKEwiW1cOE2NToAhUNRqwKHdMWDfYQ9QEwA3oECAQQBQ#imgrc=yINBvrxLrvuajM",
    },
    {
      "Bank": "First Florida Integrity Bank",
      "link": "https://www.firstfloridaintegritybank.com/Paycheck_protection_program.asp",
      "accepting": "Yes",
      "Logo Link": "https://www.firstfloridaintegritybank.com/img/logo.png",
    },
    {
      "Bank": "First Home Bank",
      "link": "https://www.firsthomebank.com/sbarelief/",
      "accepting": "No",
      "Logo Link": "https://www.firsthomebank.com/sbarelief/",
    },
    {
      "Bank": "First Horizon Bank",
      "link": "https://www.firsthorizon.com/Small-Business/Products-and-Services/Borrowing/SBA-Loans/SBA-Loans-Updates",
      "accepting": "Yes",
      "Logo Link": "https://www.bankbranchlocator.com/logo/first-national-bank-northwest-florida.jpg",
    },
    
    {
      "Bank": "First National Bank Northwest Florida",
      "link": "https://www.fnbnwfl.com/sba-ppp-paycheck-protection-program-information/",
      "accepting": "No",
      "Logo Link": "https://www.bankbranchlocator.com/logo/first-national-bank-northwest-florida.jpg",
    },
    {
      "Bank": "First National Bank Of Omaha",
      "link": "https://www.fnbo.com/pages/coronavirus-updates/business/",
      "accepting": "No",
      "Logo Link": "https://www.bankbranchlocator.com/logo/first-national-bank-northwest-florida.jpg",
    },
    {
      "Bank": "First Republic Bank ",
      "link": "https://www.firstrepublic.com/resource/sba-ppp-thank-you",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "First Southern Bank",
      "link": "https://www.fsb-bank.bank/",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "FirstBank Puerto Rico ",
      "link": "https://www.firstbankteayuda.com/es/plan-alivio-economico?utm_campaign=institucional&utm_source=website&utm_medium=alert-message&utm_content=covid-pr-sp",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "FL Renasant Bank",
      "link": "https://www.renasantbank.com/small-business-relief",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "FL Western Alliance Bank",
      "link": "https://www.westernalliancebancorporation.com/about-western-alliance-bancorporation/news/cares-act",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "Flagler Bank ",
      "link": "https://flaglerbankusa.com/",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "Florida Business Bank",
      "link": "https://www.lendio.com/ppp/florida-business-bank-46331/",
      "accepting": "No",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/007/203/1463142210_large.jpg",
    },
    {
      "Bank": "Florida Capital Bank",
      "link": "https://www.floridacapitalbank.com/here-to-help/",
      "accepting": "No",
      "Logo Link": "https://www.floridacapitalbank.com/wp-content/uploads/2020/04/Web-SBA-PPP-Document-Checklist-V2-1.pdf",
    },
    {
      "Bank": "Florida CU",
      "link": "https://info.flcu.org/small-business-relief",
      "accepting": "No",
      "Logo Link": "https://www.depositaccounts.com/content/logos/250x250/florida-cu.jpg",
    },
    {
      "Bank": "Fountainhead SBF LLC",
      "link": "https://www.fountainheadcc.com/ppp/",
      "accepting": "No",
      "Logo Link": "https://fountainheadcc.com/wp-content/uploads/Logo-FH-Main_4C-Horizontal.png",
    },
    {
      "Bank": "Grove Bank & Trust",
      "link": "https://www.grovebankandtrust.com/paycheck-protection-program",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "Hancock Whitney Bank",
      "link": "https://www.hancockwhitney.com/hubfs/Corona/SBA-Relief-Programs-for-Businesses-COVID-19.pdf",
      "accepting": "Yes",
      "Logo Link": "https://bloximages.newyork1.vip.townnews.com/theadvocate.com/content/tncms/assets/v3/editorial/8/f0/8f04107a-4cc4-11e8-85a8-3f417064ad31/5ae797074f170.image.jpg?resize=400%2C400",
    },
    {
      "Bank": "Heartland National Bank ",
      "link": "https://www.heartlandnb.com/payroll-protection-program",
      "accepting": "Yes",
      "Logo Link": "https://centralguide.net/wp-content/uploads/2018/01/hsbc-bank-usa-customer-service-phone-numbers.jpg",
    },
    {
      "Bank": "HSBC Bank USA",
      "link": "https://www.business.us.hsbc.com/en/your-partner-through-covid-19",
      "accepting": "No",
      "Logo Link": "https://centralguide.net/wp-content/uploads/2018/01/hsbc-bank-usa-customer-service-phone-numbers.jpg",
    },
    {
      "Bank": "IBERIABANK",
      "link": "https://www.iberiabank.com/business/business-loans/sba-loans/loan-assistance/",
      "accepting": "Yes",
      "Logo Link": "https://d31s10tn3clc14.cloudfront.net/imgs/deposits/Review+Logos/IBERIABANK_logo.png",
    },
    {
      "Bank": "Interamerican Bank, FSB ",
      "link": "https://www.interamericanbank.com/Commercial-Loans#SBA",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=INTERCREDIT+BANK+LOGO&tbm=isch&source=iu&ictx=1&fir=oUccgMH4f9YC6M%253A%252CdSjNAs_sl2zhqM%252C_&vet=1&usg=AI4_-kQNaND4Zov5aOO1KCXub0587JF_WA&sa=X&ved=2ahUKEwiplf_VvtToAhUqc98KHYzSDjMQ9QEwA3oECAoQBw#imgrc=-WqahEl9N_YcZM",
    },
    {
      "Bank": "Interaudi Bank",
      "link": "https://www.interaudibank.com/COVID-19",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=INTERCREDIT+BANK+LOGO&tbm=isch&source=iu&ictx=1&fir=oUccgMH4f9YC6M%253A%252CdSjNAs_sl2zhqM%252C_&vet=1&usg=AI4_-kQNaND4Zov5aOO1KCXub0587JF_WA&sa=X&ved=2ahUKEwiplf_VvtToAhUqc98KHYzSDjMQ9QEwA3oECAoQBw#imgrc=-WqahEl9N_YcZM",
    },
    {
      "Bank": "Intercredit Bank",
      "link": "https://www.intercreditbank.com/CustomContent.aspx?Name=504%20SBA%20LOAN",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=INTERCREDIT+BANK+LOGO&tbm=isch&source=iu&ictx=1&fir=oUccgMH4f9YC6M%253A%252CdSjNAs_sl2zhqM%252C_&vet=1&usg=AI4_-kQNaND4Zov5aOO1KCXub0587JF_WA&sa=X&ved=2ahUKEwiplf_VvtToAhUqc98KHYzSDjMQ9QEwA3oECAoQBw#imgrc=-WqahEl9N_YcZM",
    },
    {
      "Bank": "International Finance Bank",
      "link": "https://www.ifbbank.com/paycheckprotectionprogram/",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Intuit",
      "link": "https://aidassist.intuit.com/app/home#/home",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "JPMorgan Chase Bank",
      "link": "https://recovery.chase.com/cares1",
      "accepting": "No",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/005/1518101921_large.png",
    },
    {
      "Bank": "KeyBank",
      "link": "https://www.key.com/small-business/promo/stimulus.jsp",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=legaCY+BANK+OF+FLORIDA+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjU0anJu9ToAhXidd8KHQBFCWIQ_AUoAXoECAwQAw&biw=1500&bih=809&dpr=2#imgrc=WboJhtIlX-bc_M",
    },
    {
      "Bank": "Legacy Bank of Florida",
      "link": "https://www.legacybankfl.com/index.html",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=legaCY+BANK+OF+FLORIDA+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjU0anJu9ToAhXidd8KHQBFCWIQ_AUoAXoECAwQAw&biw=1500&bih=809&dpr=2#imgrc=WboJhtIlX-bc_M",
    },
    {
      "Bank": "Lendistry-Goldmann Sachs",
      "link": "https://lendistry-sba-ppp-form.secure.force.com/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=lendistry+bank+logo&sxsrf=ALeKk00B5y3UaXMo-W9OAazVNe7mFRt0Fw:1587653482753&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiYofaO5v7oAhVtUd8KHawqA4EQ_AUoAXoECAwQAw&biw=1920&bih=1001",
    },
    {
      "Bank": "Live Oak Banking Company ",
      "link": "https://www.liveoakbank.com/crisis-small-business-resources/",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Marine Bank & Trust Company",
      "link": "https://www.marinebankandtrust.com/assets/files/2IHbNP0f",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=marine+bank+%26+trust+company+logo&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiy27GcwNToAhVDUt8KHb_dCAQQ_AUoAXoECAsQAw&biw=1500&bih=809&dpr=2#imgrc=NMf4evfygNC6nM",
    },
    {
      "Bank": "McCoy FCU",
      "link": "https://www.mccoyfcu.org/lp/paycheck-protection-program.html",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=McCoy+FCU+LOGO&tbm=isch&source=iu&ictx=1&fir=nOenIYPf-MdB3M%253A%252CeoWgRjRpuIBXKM%252C_&vet=1&usg=AI4_-kRc4So2TyenYDEBhbMKpiK4Rw2wmg&sa=X&ved=2ahUKEwje48C92NToAhUBjq0KHUsvBQ0Q9QEwAXoECAoQBg#imgrc=nOenIYPf-MdB3M",
    },
    {
      "Bank": "Modern Bank ",
      "link": "https://www.modernbank.com/",
      "accepting": "Yes",
      "Logo Link": "",
    },
    {
      "Bank": "Navy FCU",
      "link": "https://www.navyfederal.org/about/covid19-faq.php#covid-accord-8",
      "accepting": "Yes",
      "Logo Link": "https://www.navyfederal.org/assets/images/nfcu-logo-bluegrad-800.jpg",
    },
    {
      "Bank": "Newtek Small Business Finance, Inc.",
      "link": "https://nbtc.com/business/ppp-paycheck-protection-program",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=ocean+bank+logo&tbm=isch&source=iu&ictx=1&fir=M-F_k4PHyRIHpM%253A%252Cn1jB_5yhP3H8dM%252C_&vet=1&usg=AI4_-kTIDo2f_RycB9zTlh4ukTMDKGA9oA&sa=X&ved=2ahUKEwiVo8q8yNToAhWhiOAKHYmUA8sQ9QEwAHoECAkQGA#imgrc=M-F_k4PHyRIHpM:",
    },
    {
      "Bank": "Northern Bank and Trust Company ",
      "link": "https://www.oceanbank.com/documents/ppp_flyer.pdf",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=ocean+bank+logo&tbm=isch&source=iu&ictx=1&fir=M-F_k4PHyRIHpM%253A%252Cn1jB_5yhP3H8dM%252C_&vet=1&usg=AI4_-kTIDo2f_RycB9zTlh4ukTMDKGA9oA&sa=X&ved=2ahUKEwiVo8q8yNToAhWhiOAKHYmUA8sQ9QEwAHoECAkQGA#imgrc=M-F_k4PHyRIHpM:",
    },
    {
      "Bank": "Ocean Bank",
      "link": "https://www.oceanbank.com/documents/ppp_flyer.pdf",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=ocean+bank+logo&tbm=isch&source=iu&ictx=1&fir=M-F_k4PHyRIHpM%253A%252Cn1jB_5yhP3H8dM%252C_&vet=1&usg=AI4_-kTIDo2f_RycB9zTlh4ukTMDKGA9oA&sa=X&ved=2ahUKEwiVo8q8yNToAhWhiOAKHYmUA8sQ9QEwAHoECAkQGA#imgrc=M-F_k4PHyRIHpM:",
    },
    {
      "Bank": "One Florida Bank",
      "link": "https://www.onefloridabank.com/index.htm",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/998/1463071488_large.jpg",
    },
    {
      "Bank": "One United Bank",
      "link": "https://www.oneunited.com/ppp/",
      "accepting": "Yes",
      "Logo Link": "https://prnewswire2-a.akamaihd.net/p/1893751/sp/189375100/thumbnail/entry_id/0_m68bmzj0/def_height/2700/def_width/2700/version/100012/type/1",
    },
    {
      "Bank": "Pacific National Bank",
      "link": "https://www.pnb.com/covid-19-small-business-assistance-programs",
      "accepting": "No",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/998/1463071488_large.jpg",
    },
    {
      "Bank": "Paypal",
      "link": "https://www.loanbuilder.com/ppp-loan-application",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/998/1463071488_large.jpg",
    },
    {
      "Bank": "Peoples Bank",
      "link": "https://www.peoplesbanksba.com/financialrelief",
      "accepting": "Yes",
      "Logo Link": "https://d187qskirji7ti.cloudfront.net/companies/wide_images/000/000/998/1463071488_large.jpg",
    },
    {
      "Bank": "Pinnacle Bank",
      "link": "https://www.pnfp.com/paycheck-protection-program-ppp/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=pnc+national+bank+logo&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiXorru3NToAhVpU98KHdEkAl8Q_AUoAnoECA4QBA&biw=1104&bih=620#imgrc=kZ5A5PPs1S_2uM",
    },
    {
      "Bank": "PNC Bank",
      "link": "https://www.pnc.com/en/customer-service/paycheck-protection-program.html",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=pnc+national+bank+logo&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiXorru3NToAhVpU98KHdEkAl8Q_AUoAnoECA4QBA&biw=1104&bih=620#imgrc=kZ5A5PPs1S_2uM",
    },
    {
      "Bank": "Popular Bank ",
      "link": "https://www.popularbank.com/sba-paycheck-protection/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=professional+bank&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwijt6aT7NToAhUkZjUKHTXnByoQ_AUoBHoECBAQBg&biw=1104&bih=620#imgrc=vkk6m09ZVyydpM",
    },
    {
      "Bank": "Professional Bank",
      "link": "https://www.sba.gov/funding-programs/loans/coronavirus-relief-options/paycheck-protection-program-ppp",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=professional+bank&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwijt6aT7NToAhUkZjUKHTXnByoQ_AUoBHoECBAQBg&biw=1104&bih=620#imgrc=vkk6m09ZVyydpM",
    },
    {
      "Bank": "Progress Bank and Trust",
      "link": "https://www.myprogressbank.com/About-Us/Resources/COVID-19-Resources",
      "accepting": "Yes",
      "Logo Link": "https://logos-download.com/wp-content/uploads/2017/03/Regions_Bank_logo.png",
    },
    {
      "Bank": "Readycap Lending, LLC ",
      "link": "https://readycapital.com/",
      "accepting": "Yes",
      "Logo Link": "https://logos-download.com/wp-content/uploads/2017/03/Regions_Bank_logo.png",
    },
    {
      "Bank": "Regions Bank",
      "link": "https://apply.regions.com/cares/gettingstarted",
      "accepting": "Yes",
      "Logo Link": "https://logos-download.com/wp-content/uploads/2017/03/Regions_Bank_logo.png",
    },
    {
      "Bank": "Republic Bank & Trust Company ",
      "link": "https://www.myrepublicbank.com/sba-relief-program-0",
      "accepting": "No",
      "Logo Link": "https://www.sacfcu.org/wp-content/themes/sacfcu-2017/library/images/sacfcu-logo.svg",
    },
    {
      "Bank": "Republic First Bank d/b/a Republic Bank",
      "link": "https://www.myrepublicbank.com/sba-relief-program-1",
      "accepting": "Yes",
      "Logo Link": "https://www.sacfcu.org/wp-content/themes/sacfcu-2017/library/images/sacfcu-logo.svg",
    },
    {
      "Bank": "Sabal Palm Bank",
      "link": "https://www.sabalpalmbank.com/sabal-palm-bank-paycheck-protection-loan-program/",
      "accepting": "Yes",
      "Logo Link": "https://www.sacfcu.org/wp-content/themes/sacfcu-2017/library/images/sacfcu-logo.svg",
    },
    {
      "Bank": "San Antonio Citizens FCU",
      "link": "https://www.sacfcu.org/sba-ppp/",
      "accepting": "No",
      "Logo Link": "https://www.sacfcu.org/wp-content/themes/sacfcu-2017/library/images/sacfcu-logo.svg",
    },
    {
      "Bank": "Santander Bank",
      "link": "https://www.santanderbank.com/us/business/covidrecovery",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=Santander+Bank,+National+Association+logo&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjrhuy73dToAhWMdd8KHQrsBd8Q_AUoAXoECA4QAw&biw=1104&bih=620#imgrc=LPlgS5j2tPJhtM",
    },
    {
      "Bank": "Seacoast National Bank",
      "link": "https://www.seacoastbank.com/coronavirus-cares-act-faq",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=Seacoast+National+Bank+logo&rlz=1C1GCEU_enUS893US893&tbm=isch&source=iu&ictx=1&fir=rVR9CB8V3g4QRM%253A%252Cz6TolbpqTU_RFM%252C_&vet=1&usg=AI4_-kQ-p82f686Ms4CpX2T4beVrNlW_QQ&sa=X&ved=2ahUKEwigmvu_5dToAhXymOAKHa2ICiQQ9QEwAHoECAEQFQ#imgrc=rVR9CB8V3g4QRM:",
    },
    {
      "Bank": "Seaside National Bank & Trust",
      "link": "https://www.seasidebank.com/cares-act.asp",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=https://www.seasidebank.coM+LOGO&source=lnms&tbm=isch&sa=X&ved=2ahUKEwjZo5aqv9ToAhUhheAKHQt4C7wQ_AUoAXoECAwQAw&biw=1500&bih=809#imgrc=tJ_TmVlfe0lEEM",
    },
    {
      "Bank": "SmartBank",
      "link": "https://www.smartbank.com/news/paycheck-protection-program-information/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=Seacoast+National+Bank+logo&rlz=1C1GCEU_enUS893US893&tbm=isch&source=iu&ictx=1&fir=rVR9CB8V3g4QRM%253A%252Cz6TolbpqTU_RFM%252C_&vet=1&usg=AI4_-kQ-p82f686Ms4CpX2T4beVrNlW_QQ&sa=X&ved=2ahUKEwigmvu_5dToAhXymOAKHa2ICiQQ9QEwAHoECAEQFQ#imgrc=rVR9CB8V3g4QRM:",
    },
    {
      "Bank": "Square Capital",
      "link": "https://squareup.com/us/en/l/sba-ppp-loans",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=suncoast+bank+logo&tbm=isch&source=iu&ictx=1&fir=N9eqs5d8_Qj2EM%253A%252Cguq239FPfESYPM%252C_&vet=1&usg=AI4_-kTTmsSv0d6wPOAX7gZt7FyjmCZdrQ&sa=X&ved=2ahUKEwinp_Ca0NToAhWyneAKHZNZDMYQ9QEwAHoECAcQHA#imgrc=N9eqs5d8_Qj2EM:",
    },
    {
      "Bank": "Suncoast CU",
      "link": "https://www.suncoastcreditunion.com/emergency-updates-business",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=suncoast+bank+logo&tbm=isch&source=iu&ictx=1&fir=N9eqs5d8_Qj2EM%253A%252Cguq239FPfESYPM%252C_&vet=1&usg=AI4_-kTTmsSv0d6wPOAX7gZt7FyjmCZdrQ&sa=X&ved=2ahUKEwinp_Ca0NToAhWyneAKHZNZDMYQ9QEwAHoECAcQHA#imgrc=N9eqs5d8_Qj2EM:",
    },
    {
      "Bank": "Sunrise Bank ",
      "link": "https://sunrisebanks.com/paycheck-protection-program/",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=suncoast+bank+logo&tbm=isch&source=iu&ictx=1&fir=N9eqs5d8_Qj2EM%253A%252Cguq239FPfESYPM%252C_&vet=1&usg=AI4_-kTTmsSv0d6wPOAX7gZt7FyjmCZdrQ&sa=X&ved=2ahUKEwinp_Ca0NToAhWyneAKHZNZDMYQ9QEwAHoECAcQHA#imgrc=N9eqs5d8_Qj2EM:",
    },
    {
      "Bank": "Sunstate Bank",
      "link": "https://www.sunstatefl.com/notice-details.html",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=suncoast+bank+logo&tbm=isch&source=iu&ictx=1&fir=N9eqs5d8_Qj2EM%253A%252Cguq239FPfESYPM%252C_&vet=1&usg=AI4_-kTTmsSv0d6wPOAX7gZt7FyjmCZdrQ&sa=X&ved=2ahUKEwinp_Ca0NToAhWyneAKHZNZDMYQ9QEwAHoECAcQHA#imgrc=N9eqs5d8_Qj2EM:",
    },
    {
      "Bank": "Synovus Bank",
      "link": "https://www.synovus.com/covid-19/paycheck-protection-program/required-documents/",
      "accepting": "Yes",
      "Logo Link": "https://www.synovus.com/-/media/images/logo/synovus-logo-red.svg?la=en&hash=E4FF7FEFC1B85D86CAB2DB20876678D1D4EF3ABF",
    },
    {
      "Bank": "TD Bank",
      "link": "https://www.td.com/us/en/small-business/covid-sba/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/imgres?imgurl=https%3A%2F%2Fwww.brandeps.com%2Flogo-download%2FT%2FTD-Bank-logo-vector-01.svg&imgrefurl=https%3A%2F%2Fwww.brandeps.com%2Flogo%2FT%2FTD-Bank-01&tbnid=U2R_7lOsaf4X2M&vet=12ahUKEwjr257TrNToAhVKZKwKHao2CFAQMygAegUIARD7AQ..i&docid=X0i6ygiNEtb-2M&w=800&h=800&q=td%20bank%20logo%20png&ved=2ahUKEwjr257TrNToAhVKZKwKHao2CFAQMygAegUIARD7AQ",
    },
    {
      "Bank": "Texas Capital Bank",
      "link": "https://www.texascapitalbank.com/covid-19-small-businesses/paycheck-protection-program",
      "accepting": "Yes",
      "Logo Link": "https://www.google.com/search?q=the+bank+of+tampa&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiXp6rJ5tToAhVThHIEHYxSCt8Q_AUoA3oECAsQBQ&biw=1104&bih=620#imgrc=r95UyZenLJNBIM",
    },
    {
      "Bank": "The Bank of Tampa",
      "link": "https://www.bankoftampa.com/news/important-cares-act-and-ppp-information/",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=the+bank+of+tampa&rlz=1C1GCEU_enUS893US893&source=lnms&tbm=isch&sa=X&ved=2ahUKEwiXp6rJ5tToAhVThHIEHYxSCt8Q_AUoA3oECAsQBQ&biw=1104&bih=620#imgrc=r95UyZenLJNBIM",
    },
    {
      "Bank": "The First National Bank of South Miami",
      "link": "https://forms.office.com/Pages/ResponsePage.aspx?id=K4FxoqQC_0mLGvMavAJv2H7LPkqelMxBp_9s5ffOMMtUN0JWWURDWUJSUTlJRTI2WllLTDU1TVBCUS4u",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=The+First+National+Bank+of+South+Miami+LOGO&tbm=isch&source=iu&ictx=1&fir=g-fyLlI5zdXvJM%253A%252C7oxQ0NzPfYceFM%252C_&vet=1&usg=AI4_-kTF3bMwSgjdIKhuIUXpiFViNCjAGA&sa=X&ved=2ahUKEwiNueWk2NToAhUDKK0KHQKoCaQQ9QEwBHoECAoQEQ#imgrc=g-fyLlI5zdXvJM",
    },
    {
      "Bank": "Truist Bank",
      "link": "https://www.truist.com/coronavirus-response/banking-solutions/business?icid=PaymentRelief_SmallBusiness_HomepageAlert_TruistSmallBusiness",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "Trustmark National Bank",
      "link": "https://www.trustmark.com/caresact",
      "accepting": "Yes",
      "Logo Link": "https://media-exp1.licdn.com/dms/image/C4E0BAQE1lQHC3nkM4A/company-logo_200_200/0?e=2159024400&v=beta&t=8S06T0x9_M-928INQDYGN-pXIF05aTdrdFWPvSD6WUM",
    },
    {
      "Bank": "U.S. Bank",
      "link": "https://www.usbank.com/business-banking/business-lending/sba-loans/paycheck-protection-program.html",
      "accepting": "No",
      "Logo Link": "https://media-exp1.licdn.com/dms/image/C4E0BAQE1lQHC3nkM4A/company-logo_200_200/0?e=2159024400&v=beta&t=8S06T0x9_M-928INQDYGN-pXIF05aTdrdFWPvSD6WUM",
    },
    {
      "Bank": "U.S. Century Bank",
      "link": "https://www.uscentury.com/Business/Small-Business-SBA-Loans",
      "accepting": "No",
      "Logo Link": "https://www.google.com/search?q=US+CENTURY+BANK+LOGO&tbm=isch&source=iu&ictx=1&fir=XUxbHvJk_vELxM%253A%252C7N9tiLNyfHxPWM%252C_&vet=1&usg=AI4_-kQvDn8dEpgaGkFwNWSvwG05c-0mCw&sa=X&ved=2ahUKEwiNx9-_vtToAhWkd98KHarRBKIQ9QEwA3oECAoQGg#imgrc=XUxbHvJk_vELxM:",
    },
    {
      "Bank": "Valley Bank",
      "link": "https://www.valley.com/coronavirus/relief-programs/sba-paycheck-protection-program",
      "accepting": "No",
      "Logo Link": "https://www.google.com/imgres?imgurl=https%3A%2F%2Fcdn.newsday.com%2Fpolopoly_fs%2F1.21861852.1539370301!%2FhttpImage%2Fimage.jpg_gen%2Fderivatives%2Flandscape_768%2Fimage.jpg&imgrefurl=https%3A%2F%2Fwww.newsday.com%2Fbusiness%2Fvalley-national-bank-rebrand-logo-1.21860565&tbnid=15U3YCRvc7q2IM&vet=12ahUKEwiJsJ_OwtToAhWFRawKHZxGDxcQMygAegUIARDkAQ..i&docid=5rsXWMDfEfohxM&w=768&h=431&q=valley%20bank%20logo&ved=2ahUKEwiJsJ_OwtToAhWFRawKHZxGDxcQMygAegUIARDkAQ",
    },
    {
      "Bank": "Wells Fargo Bank",
      "link": "https://update.wf.com/coronavirus/paycheckprotectionprogram/?_ga=2.45348711.235318929.1586200071-680335097.1586200071",
      "accepting": "No",
      "Logo Link": "",
    },
    {
      "Bank": "WebBank",
      "link": "https://www.key.com/small-business/promo/stimulus.jsp",
      "accepting": "No",
      "Logo Link": "",
    },
  ],
}

export default Banks
